export const TrackingOrigin = Object.freeze({
  LeadForm: Object.freeze({
    PackageDetail: "package_detail",
    CompareList: "compare_list",
    HomePage: "home_page",
    PackageList: "package_list",
    PackageListNoResult: "package_list_no_result",
  }),
});
export const TrackingView = Object.freeze({
  LoginForm: Object.freeze({
    PhoneNumber: "phone_number",
    VerifyOtp: "verify_otp",
    UpdateProfile: "update_profile",
    CustomizeForm: "customise_form",
    RequestCallForm: "request_call_form",
  }),
});
export const TrackingPageName = Object.freeze({
  None: "none",
  Home: "home_page",
  PackageList: "package_list_page",
  CompareList: "compare_list_page",
  PackageDetail: "package_detail_page",
  About: "about_us_page",
  TermsAndConditions: "terms_and_conditions_page",
  PivacyPolicy: "privacy_policy_page",
  WorkWithUs: "work_with_us_page",
  ThankYou: "thank_you_page",
  WhyTripstore: "why_trip_store_page",
  AsiaLandingPage: "asia_landing_page",
  IndiaLandingPage: "india_landing_page",
  EuropeLandingPage: "europe_landing_page",
  AfricaLandingPage: "africa_landing_page",
  AustraliaLandingPage: "australia_landing_page",
});

export const getTrackingPageName = (path) => {
  switch (true) {
    case path.startsWith("/packages"):
      return TrackingPageName.PackageList;
    case path.startsWith("/comparelist"):
      return TrackingPageName.CompareList;
    case path.startsWith("/Conditions"):
      return TrackingPageName.TermsAndConditions;
    case path.startsWith("/About"):
      return TrackingPageName.About;
    case path.startsWith("/policy"):
      return TrackingPageName.PivacyPolicy;
    case path.startsWith("/workTs"):
      return TrackingPageName.WorkWithUs;
    case path.startsWith("/thanks"):
      return TrackingPageName.ThankYou;
    case path.startsWith("/packagedetails"):
      return TrackingPageName.PackageDetail;
    case path.startsWith("/bookingTs"):
      return TrackingPageName.WhyTripstore;
    case path.startsWith("/d/asia-tour-packages"):
      return TrackingPageName.AsiaLandingPage;
    case path.startsWith("/d/asia/india-tour-packages"):
      return TrackingPageName.IndiaLandingPage;
    case path.startsWith("/d/europe-tour-packages"):
      return TrackingPageName.EuropeLandingPage;
    case path.startsWith("/d/africa-tour-packages"):
      return TrackingPageName.AfricaLandingPage;
    case path.startsWith("/d/australia-new-zealand-tour-packages"):
      return TrackingPageName.AustraliaLandingPage
    default:
      return TrackingPageName.Home;
  }
};

export const TrackingEventName = Object.freeze({
  AppOpen: "app_open",
  CustomizeFormOpen: "form_open_customize",
  RequestCallFormOpen: "form_open_request_call",
  RequestCallFormSubmit: "form_submit_request_call",
  PageView: "page_view",
  WidegetScroll: "widget_scroll",
  PageScroll: "page_scroll",
  NavbarCallButtonClicked: "nav_bar_call_click",
  NavbarCallButtonClickedWithOrigin: "click_call",
  NavbarLoginButtonClicked: "click_login",
  NavbarWhatsappButtonClicked: "click_whatsapp",
  HomeWidgetItemClick: "home_widget_item_click",
  SearchIntentStart: "search_intent_start",
  SearchComplete: "search_complete",
  SearchIntentEnd: "search_intent_end",
  ListingFilterOpen: "listing_filter_open",
  ListingFilterClearAllClick: "listing_filter_clear_all_click",
  ListingFilterItemClick: "listing_filter_item_click",
  ListingFilterItemClearClick: "listing_filter_item_clear_click",
  ListingFilterDoneClick: "listing_filter_done_click",
  ListingPackageViewDetailClick: "listing_package_view_detail_click",
  ListingAddToCompareClick: "listing_add_to_compare_click",
  ListingCompareAction: "listing_compare_action",
  ListingRemoveFromCompareClick: "listing_remove_from_compare_click",
  ListingRequestCallClick: "listing_request_call_click",
  ListingRequestCallSubmit: "listing_request_call_submit",
  FilterApplied: "filter_applied",
  ListingPagination: "listing_pagination",
  PackageOpenWhatsapp: "package_open_whatsapp",
  LoginSubmitPhoneNumber: "login_submit_phone_click",
  LoginResendOtpClick: "login_resend_otp_click",
  LoginValidateOtpClick: "login_validate_otp_click",
  LoginSuccess: "login_success",
  LoginUpdateProfileOpen: "login_update_profile_open",
  LoginUpdateProfileClick: "login_update_profile_click",
  LoginEnd: "login_end",
  FormSubmitCustomize: "form_submit_customize",
  FormSubmitRequestCall: "form_submit_request_call",
  LogoutClick: "logout_click",
  FormEndCustomize:"form_end_customize",
  FormEndRequestCall:"form_end_request_call",
  ClickScrollToLeadForm:"click_scroll_to_lead_form",
  FormSubmitPage: "form_submit_page",
  DepartureDateClick:"departure_date_click",
  BookPackageClick:"book_package_click",
  BookingTravelerCountChange:"booking_traveler_count_change",
  BookingConfirmClick:"booking_confirm_click",
  ListingFilterClick:"listing_filter_click",
  ListingFilterOpen:"listing_filter_open",
  ListingFilterClearAllClick:"listing_filter_clear_all_click",
  FilterAppliedCount:"filter_applied_count",
  ListingFilterApplyClick:"listing_filter_apply_click",
  ListingFilterCloseClick:"listing_filter_close_click",
  ListingFilterCloseCancelClick:"listing_filter_close_cancel_click",
  ListingFilterCloseContinueClick:"listing_filter_close_continue_click",
  ListingFilterChipClick:"listing_filter_chip_click"
});

export const TrackerPropertyKey = Object.freeze({
  Event: "event",
  FormType: "form_type",
  Origin: "origin",
  PackageId: "package_id",
  PackageIds: "package_ids",
  PackageName: "package_name",
  PackageNames: "package_names",
  PackagePrices: "package_prices",
  BrowsingPage: "browsing_page",
  WidgetType: "widget_type",
  ItemTitle: "item_title",
  widgetIdentifier: "widget_identifier",
  widgetItemTitle1: "widget_item_title1",
  widgetItemTitle2: "widget_item_title2",
  UserQuery: "user_query",
  SearchType: "search_type",
  SearchStringWhere: "search_string_where",
  SearchStringWhen: "search_string_when",
  SearchStringBudget: "search_string_budget",
  searchWhereUserQuery: "search_where_user_query",
  searchWhereAutocompletedSuggestion: "search_where_autocompleted_suggestion",
  searchWhereGoogleSuggestion: "search_where_google_suggestion",
  City: "city",
  Country: "country",
  Destination: "destination",
  Month: "month",
  Price: "price",
  IsSelected: "is_selected",
  SelectedOptions: "selected_options",
  FilterTitle: "filter_title",
  PackageTitle: "package_title",
  PackagePosition: "package_position",
  PackageSlug: "package_slug",
  PackagePrice: "package_price",
  Count: "count",
  FilterType: "filter_type",
  FilterValues: "filter_values",
  PageNumber: "page_number",
  TotalPages: "total_pages",
  Impressions: "impressions",
  OriginPage: "origin_page",
  TimeSpent: "time_spent",
  ResendCount: "resend_count",
  EndScreen: "end_screen",
  Where: "where",
  When: "when",
  Budget: "budget",
  TravelersCount: "travelers_count",
  Comment: "comment",
  Discount: "discount",
  InfoFilled:"info_filled",
  FilterAppliedCount:"filter_applied_count",
});

export const TrackingEventValues = Object.freeze({
  Customize: "customize",
  RequestCall: "request_call",
});
