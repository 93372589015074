import axios from "axios";
import apiUrl from "../../../urls/apiUrls";
import { Tracker } from "../../../tracking/tracker";

const accessToken = localStorage.getItem("access_token");

export const homePageWidgetList = async (pageName) => {
  try {
    const homePageResponse = await axios.get(
      `${apiUrl.homePageBaseUrl}/page/${pageName}`
    )

    if (homePageResponse.status === 200) {
      return homePageResponse;
    }
  } catch (e) {
    return e.response;
  }
};

export const homeSearchPlacesList = async (page) => {
  try {
    const searchList = await axios.get(
      `${apiUrl.homePageBaseUrl}/search/home/metadata`
    );

    if (searchList.status === 200) {
      return searchList;
    }
  } catch (e) {
    return e.response;
  }
};

export const loginUser = async (
  countryCode,
  mobileNumber,
  isWhatsappChecked,
  page_url,
  package_id
) => {
  try {
    const payload = {
      country_code: countryCode,
      phone_number: mobileNumber,
      is_whatsapp_enabled: isWhatsappChecked,
      page_url: page_url,
      package_id: package_id ? package_id : null,
    };

    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/auth/register/phone`,
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyUserWithOtp = async (
  countryCode,
  mobileNumber,
  otp,
  lead_id
) => {
  try {
    const payload = {
      country_code: countryCode,
      phone_number: mobileNumber,
      otp: otp,
      lead_id: lead_id,
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/auth/verify/otp`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const resendOtp = async (countryCode, mobileNumber) => {
  try {
    const payload = {
      country_code: countryCode,
      phone_number: mobileNumber,
    };

    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/auth/resend/otp`,
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const registerUser = async (name, email, accessToken) => {
  try {
    const payload = {
      name: name,
    };
    if (email) {
      payload.email = email;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.put(
      `${apiUrl.homePageBaseUrl}/profile`,
      payload,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const logoutUser = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/profile/logout`,
      {},
      config
    );
    if (response.status >= 200 && response.status < 300) {
      Tracker.getInstance().logout();
      window.location.reload();
      localStorage.removeItem("access_token");
    }
  } catch (error) {
    if (error.response.status >= 400 && error.response.status < 500) {
      Tracker.getInstance().logout();
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  }
};

export const deleteUser = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.delete(
      `${apiUrl.homePageBaseUrl}/profile`,
      config
    );
    if (response.status >= 200 && response.status < 300) {
      window.location.reload();
      localStorage.removeItem("access_token");
    }
  } catch (error) {
    return error;
  }
};

export const getUserProfileDetails = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${apiUrl.homePageBaseUrl}/profile`,
      config
    );

    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};

export const refreshToken = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/auth/refresh/token`,
      {},
      config
    );
    return response;
  } catch (error) {
    if (error.response.status >= 400 && error.response.status < 500) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  }
};

export const countryCodeList = async (page) => {
  try {
    const sessionCache = sessionStorage.getItem("countryCodeList");
    if (sessionCache) {
      console.log("countryCodeList from cache");
      return JSON.parse(sessionCache);
    }
    
    const response = await axios.get(`${apiUrl.homePageBaseUrl}/startup`);

    if (response.status >= 200 && response.status < 300) {
      sessionStorage.setItem("countryCodeList", JSON.stringify(response));
      return response;
    }
  } catch (e) {
    return e.response;
  }
};

export const generateLead = async (
  destination,
  departure_month,
  budget,
  name,
  email,
  country_code,
  phone_number,
  package_id,
  travelers_count,
  comment,
  formAccessToken,
  page_url,
  source,
  page_name
) => {
  try {
    const payload = {
      name: name,
      email: email,
      country_code: country_code,
      phone_number: phone_number,
      page_url: page_url,
      package_id: package_id ? package_id : null,
      source: source,
    };
    if (destination) {
      payload.destination = destination;
    }

    if (departure_month) {
      payload.departure_month = departure_month;
    }
    if (budget) {
      payload.budget = budget;
    }
    if (package_id) {
      payload.package_id = package_id;
    }

    if (travelers_count) {
      payload.travelers_count = travelers_count;
    }

    if (comment) {
      payload.comment = comment;
    }

    if (page_name) {
      payload.page_name = page_name;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${
          formAccessToken ? formAccessToken : accessToken
        }`,
      },
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/lead/submit`,
      payload,
      config
    );

    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBookingAvailibilty = async (packageId) => {
  try {
    const response = await axios.get(
      `${apiUrl.homePageBaseUrl}/package/${packageId}/availability`
    );

    if (response.status >= 200 && response.status < 300) {
      return response;
      }
  } catch (e) {
    return e.response;
  }
}

export const getFiltersList = async (payload,cancelToken) => {
  let params = new URLSearchParams();

  payload.forEach((filter, index) => {
    params.append("filters[]", JSON.stringify(filter));
  });
  params.append("all", false);

  try {
    const filterList = await axios.get(
      `${apiUrl.tripStoreBaseUrl}/api/filter`,
      {
        params: params,
        cancelToken,
      }
    );

    if (filterList.status === 200) {
      return filterList;
    }
  } catch (e) {
    return e.response;
  }
};

export const createBookingIntent = async (package_id, availability_id) => {
  try {
    const payload = {
      package_id: package_id,
      availability_id: availability_id,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/bookingIntent`,
      payload,
      config
    );
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  } catch (error) {
    if (error.response.status >= 400 && error.response.status < 500) {
      return error.response;
    }
  }
};

export const getBookingIntent = async (intentId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${apiUrl.homePageBaseUrl}/bookingIntent/${intentId}`,
      config
    );

    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};

export const getRegionList = async () => {
  try {
    const response = await axios.get(
      `${apiUrl.tripStoreBaseUrl}/api/destination/Region`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};

export const updateTravellersCount = async (adults_count, intentId) => {
  try {
    const payload = {
      adults_count: adults_count,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.put(
      `${apiUrl.homePageBaseUrl}/bookingIntent/${intentId}`,
      payload,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const createBooking = async (intentId) => {
  try {
    const payload = {
      intent_id: intentId,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${apiUrl.homePageBaseUrl}/booking`,
      payload,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};
